/* CSS for GLOBUSPROJECT */
@import "../../../../../../lib/uio1/profile/css/util.less";
@import "typography.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}
:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

html,
body {
  font-family: @font-sans-serif, Arial, sans-serif;
  line-height: 2.6rem;
  color: @color-dark;
}

a {
  font-family: @font-serif;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  font-family: @font-serif;
  font-size: 2rem;
}

.introduction-div,
.vrtx-introduction-big,
.vrtx-frontpage-box.introduction .vrtx-box-content,
.vrtx-organizational-unit-introduction,
.vrtx-introduction {
  font-family: @font-serif;
  font-size: 2rem;
  line-height: 3.2rem;
}

h1,
h2,
h3,
h4,
h2 a {
  font-family: @font-sans-serif;
  color: @color-dark;
  font-weight: 600;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2.6rem;
}

.vrtx-frontpage-box .item-title,
h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.6rem;
}

hr {
  border-bottom: 4px solid @color-primary--air;
  padding-bottom: 10px;
}

// UU

#hidnav a[href="#bottomnav"] {
  display: none;
}

#main .vrtx-back a::before {
  background: rgba(0, 0, 0, 0)
    url("../images/icon-chevron-back-green.svg")
    no-repeat scroll 0 2px;
}

.vrtx-message-line-publish-date,
.vrtx-comment .comment-date,
.lastModified,
.vrtx-event-component-misc,
div.time-and-place-container,
.published-date,
.vrtx-image-creation-time,
.vrtx-byline,
.vrtx-featured-item .vrtx-date,
.vrtx-authors,
.vrtx-date-info {
  color: #555;
}

.vrtx-imagetext {
  color: #777;
}

.not-for-ansatte.header-search-collapsable {
  .header-search-expand {
    background-color: @color-secondary;
    font-family: @font-sans-serif;
    color: #fff;
  }

  .header-search-expand:hover,
  .header-search-expand:focus {
    background-color: #33669a !important;
    text-decoration: none;
  }
}

.not-for-ansatte {
  #head-wrapper {
    background-color: @color-primary--air;
    #head,
    #header {
      height: 165px;
    }
    #head {
      #header {
        #header-logo {
          padding-top: 44px;
          a {
            text-decoration: none;
            font-family: @font-sans-serif;
            font-weight: 600;
            font-size: 1.7rem;
          }
          a:focus {
            text-decoration: underline;
          }
        }
        a.head-logo {
          height: 60px;
          position: absolute;
          top: 55px;
          width: 388px;
          span.head-title {
            font-size: 6.35rem;
            font-stretch: condensed;
            font-weight: 300;
            margin-left: 0;
            position: absolute;
            text-transform: uppercase;
            top: 75px;
            width: 270px;
          }
        }
        a.head-logo:hover,
        a.head-logo:focus {
          color: @color-neutral--dark !important;
          text-decoration: none;
        }
      }
    }
    .vrtx-frontpage-box-picture img {
      margin-top: -36px;
    }
    .uio-app-name {
      display: none;
    }
    .header-search {
      top: 18px;
      z-index: 10;
      input[type="text"] {
        background: @color-secondary--lighter;
        .placeholderColor(@color-neutral--dark, @color-neutral--dark);
        color: @color-neutral--dark;
      }
      button {
        background: @color-secondary !important;
      }
      button:hover,
      button:focus {
        background: #33669a !important;
      }
    }

    .head-menu {
      right: 120px;
      .vrtx-login-manage-component .vrtx-dropdown-link {
        background: transparent
          url("../images/language-dropdown-toggle-black.png")
          no-repeat scroll 100% 53%;
      }
      .language {
        display: none !important;
      }
      ul > li a,
      .vrtx-login-manage-component > a {
        color: @color-neutral;
      }
    }
  }
}

#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul {
    li,
    li.vrtx-active-item {
      background: @color-primary;
      a {
        color: @color-light;
        padding: 8px 15px 11px !important;
      }
      a:hover,
      a:focus {
        text-decoration: underline;
        color: @color-light !important;
        background-color: @color-secondary !important;
      }
    }
    li.vrtx-active-item a {
      background-color: @color-primary--light;
      color: @color-dark;
    }
  }
}

#left-main {
  .vrtx-breadcrumb-menu li > span.vrtx-marked,
  .vrtx-breadcrumb-menu li > a.vrtx-marked {
    background: @color-primary--light;
    color: #222;
  }
  .vrtx-breadcrumb-menu li > span,
  .vrtx-breadcrumb-menu li > a {
    background: @color-primary--air;
    font-family: @font-sans-serif;
  }
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-green.svg")
    no-repeat 0 3px !important;
}

#main .vrtx-frontpage-box.top-line {
  border-top: 4px solid @color-primary--air;
  padding-top: 30px;
  margin-bottom: 30px;
  &.twitter-fit-box {
    height: 400px !important;
  }
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-green.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-green.svg");
  }
}

.vrtx-person-listing-phone,
.vrtx-person-listing-email {
  display: none;
}

table.vrtx-person-listing {
  td.vrtx-person-listing-name {
    .vrtx-image {
      img {
        border-radius: 50%;
        width: 120px;
        padding: 0;
      }
      padding-right: 14px;
    }
  }
}
#vrtx-person-contact-info-wrapper {
  .vrtx-person-image {
    border-radius: 50%;
    width: 120px;
    padding: 0;
  }
}
.round-image {
  img {
    border-radius: 50%;
    width: 120px;
    padding: 0;
  }
}
table {
  th {
    font-weight: 600;
  }
}

#left-main {
  .vrtx-breadcrumb-menu {
    li.vrtx-child > {
      span.vrtx-after-marked,
      a.vrtx-after-marked,
      span.vrtx-marked,
      a.vrtx-marked {
        border-top: none;
      }
    }
  }
}

ul.social-follow {
  margin-left: -14px !important;
  > li {
    &::before {
      content: "";
    }
  }
}


#vrtx-additional-content
  .vrtx-search-main-links-box
  ul
  li#vrtx-search-main-link-altinnhold
  span {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  padding-left: 14px;
}

.vrtx-tags,
.vrtx-authors,
.vrtx-date-info,
table td,
table th,
#main .vrtx-paging-feed-wrapper,
#main #vrtx-daily-events .vrtx-daily-events-listing,
#main .vrtx-resources .vrtx-daily-events-listing,
#main #vrtx-daily-events .vrtx-resource,
#main .vrtx-resources .vrtx-resource,
#vrtx-person-contact-info-extras {
  border-color: @color-primary--air;
}

span.vrtx-tag-cloud-title {
  display: none;
}

blockquote {
  border-color: @color-primary;
}

#main
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line)
  .vrtx-frontpage-box.third-box-right {
  border-left: none;
}
#main
  .row-thirds-double:not(.row-one-colored):not(.row-thirds-double-no-vertical-line)
  .vrtx-frontpage-box.third-box-left {
  border-right: none;
}
.vrtx-frontpage-box.big-image {
  img {
    width: 100% !important;
  }
  > h2 {
    margin-left: 0px !important;
    margin-top: 125px;
  }
  .vrtx-box-content {
    margin-left: 0px !important;
  }
  .vrtx-frontpage-box-picture {
    padding-top: 40px;
  }
}
.vrtx-frontpage-box-picture.big-image.half-box-left {
  img {
    padding-right: 20px;
  }
}

.vrtx-paging-feed-wrapper a {
  font-family: @font-sans-serif;
}

#vrtx-searchview {
  #vrtx-content,
  #right-main {
    width: 100%;
  }
  #vrtx-main-content {
    width: 738px;
  }
  #left-main {
    display: none !important;
  }
  .button.submit-button-link {
    font-family: @font-sans-serif;
  }
}

/* Footer */
#bottomnav {
  display: none !important;
}

#footer-wrapper {
  color: @color-dark;
  background: @color-primary--footer !important;
  margin-top: 40px;
  h2 {
    color: @color-dark;
  }

  .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
    background: transparent url("../images/dropdown-black.png") no-repeat scroll
      right center / 22px auto;
  }
  .vrtx-dropdown-component-toggled .vrtx-login-manage-link:focus,
  .vrtx-dropdown-component-toggled .vrtx-login-manage-link:hover {
    background-image: url("../uio1/images/dropdown.png");
  }
  #footers {
    font-size: 1.4rem !important;
    .social-components {
      margin: 5px 0 25px 3px ;
      a.facebook,
      a.twitter-x,
      a.mailto {
        color: transparent;
        margin-right: 20px;
        padding: 0;
        width: 45px;
        height: 45px;
        display: inline-block;
        &:hover,
        &:focus {
          color: transparent;
          opacity: 0.6;
        }
        &.facebook {
          background: transparent url(../uio1/images/social-list/black-svg/facebook.svg) no-repeat
            scroll center center;
          background-size: 100% auto;
        }
        &.mailto {
          background: transparent url(../uio1/images/social-list/black-svg/mail.svg) no-repeat
            scroll center center;
          background-size: 100% auto;
        }
        &.twitter-x {
          background: transparent url(../uio1/images/social-list/black-svg/twitter-x.svg) no-repeat
            scroll center center;
          background-size: 100% auto;
        }
      }
    }
    .info > p {
      float: left;
      margin-top: 0;
      margin-bottom: 10px;
    }
    .eu-flag > span {
      padding-top: 20px;
      float: left;
      padding-left: 10px;
    }
    .eu-flag > img {
      padding-top: 20px;
      float: left;
    }
    .col-3-5 .col-1-3:last-child > * {
      float: right;
      margin-top: 15px;
      margin-left: 10px;
    }
    .col-1-3 {
      width: 50%;
    }
    .col-2-5 {
      width: 30%;
    }
    .col-3-5 {
      width: 70%;
      padding-top: 0px;
    }
    a {
      color: @color-dark;
    }
    background: none;
  }
  .content {
    margin-left: 40px;
    a {
      font-family: @font-sans-serif;
    }
  }
}

#footer-wrapper.red {
  background: @color-primary--footer none;
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte #head-wrapper #head #header #header-logo {
    padding: 25px 15px 0 15px;
  }

  #menu-wrapper .menu li.active {
    background: @color-primary;
  }

  #footer-wrapper #footers .col-2-5,
  #footer-wrapper #footers .col-3-5 {
    width: 100%;
  }
}

/*------------------------------------*\
# colour
\*------------------------------------*/
// Primary, secondary etc...
@color-primary: #849c54;
@color-primary--footer: #a1b678;
@color-primary--light: #b3cca1;
@color-primary--air: #f0f7eb;
@color-secondary: #2e85c3;
@color-secondary--light: #9ab3cd;
@color-secondary--lighter: #c2d2e2;

// Neutral colors
@color-neutral--dark: #4f4f4f;
@color-neutral: #949494;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #222222;
